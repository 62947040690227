import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				hizmetler | FitZone Gym 
			</title>
			<meta name={"description"} content={"FitZone, modern spor salonumuz ve sunduğumuz geniş hizmet yelpazesi hakkında bilgi edinin."} />
			<meta property={"og:title"} content={"hizmetler | FitZone Gym "} />
			<meta property={"og:description"} content={"FitZone, modern spor salonumuz ve sunduğumuz geniş hizmet yelpazesi hakkında bilgi edinin."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://varuntarest.com/img/43625757.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://varuntarest.com/img/10.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
				width="80%"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline1">
					Hizmetlerimiz
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="left">
					FitZone, fitness ihtiyaçlarınızı karşılamak için geniş bir hizmet yelpazesi sunar. Hizmetlerimiz şunları içerir:
					<br />
					<br />
					<br />
					<Strong>
						Özel Antrenmanlar:{" "}
					</Strong>
					<br />
					sertifikalı antrenörlerimizle bireysel seanslar, belirli hedeflerinize ulaşmanıza yardımcı olur.
					<br />
					<br />
					<Strong>
						Grup Fitness Dersleri:{" "}
					</Strong>
					<br />
					yoga, spinning, HIIT ve daha fazlasını içeren çeşitli dersler.
					<br />
					<br />
					<Strong>
						Beslenme:{" "}
					</Strong>
					<br />
					diğer diyet ihtiyaçlarınızı ve fitness hedeflerinizi desteklemek için kişiselleştirilmiş tavsiyeler ve planlar.
					<br />
					<br />
					<Strong>
						Wellness Seminerleri:{" "}
					</Strong>
					<br />
					yaralanma önleme, ruh sağlığı ve genel refah gibi konularda eğitim oturumları.
					<br />
					<br />
					<br />
					<Strong>
						Masaj Hizmetleri:
					</Strong>
					<br />
					Profesyonel masaj hizmetlerimizle rahatlayın ve yenilenin.
					<br />
					<br />
					{" "}Kapsamlı yaklaşımımız, fitness yolculuğunuzda başarılı olmanız için gereken her şeye sahip olmanızı sağlar.
				</Text>
			</Box>
		</Section>
		<Components.FoodImages />
		<Components.Footer />
	</Theme>;
});